﻿import { StatusCodes } from 'http-status-codes';

import { ModelDeserializer } from './model-deserializer';

export interface ApiResponse<T> extends Response {
    content: T | T[]
}

export interface ApiError extends Error {
    request: Request,
    response: Response,
    content: any
}

export function isApiError(o): o is ApiError {
    return o instanceof Error && (o as ApiError).response !== undefined;
}

export abstract class ApiServiceBase<T> {
    constructor(private accessToken: string) { }

    private readonly deserializer = new ModelDeserializer();

    protected abstract readonly Type: new () => T;

    protected async getSingle(url: string): Promise<T> {
        try {
            let response = await this.fetch(url, { method: 'get' });
            return response.content as T;
        }
        catch (e) {
            if (isApiError(e) && e.response.status === StatusCodes.NOT_FOUND) return null;
            throw e;
        }
    }

    protected async getMany(url: string): Promise<T[]> {
        let response = await this.fetch(url, { method: 'get' });
        return response.content as T[];
    }

    protected post(url: string, body: any) {
        return this.fetch(url, { method: 'post', body });
    }

    protected async fetch(url: string, request: RequestInit) {

        // Use a copy
        const httpRequest = new Request(url, Object.assign({}, request, { body: request.body && JSON.stringify(request.body) }));

        // The request uses json
        if (!httpRequest.headers.has('accept')) httpRequest.headers.set('accept', 'application/json');
        if (!httpRequest.headers.has('content-type')) httpRequest.headers.set('content-type', 'application/json');

        if (this.accessToken && !httpRequest.headers.has('authorization')) { httpRequest.headers.set('authorization', `Bearer ${this.accessToken}`); }

        let response = await fetch(httpRequest) as ApiResponse<T>;
        
        await throwOnResponseError(httpRequest, response);

        let data = await response.json();
        if (Array.isArray(data)) response.content = data.map(d => this.deserializer.deserialize(this.Type, d));
        else response.content = this.deserializer.deserialize(this.Type, data);
        
        return response;
    }

}

async function throwOnResponseError(request: Request, response: Response) {
    if (!response.ok) {
        let content: any;
        try {
            content = await response.json();
        }
        catch (ex) { /* Not JSON. Move on with the original error. */ }
        let error = Object.assign(new Error(response.statusText), { response, request, content }) as ApiError;
        throw error;
    }
}
﻿import 'regenerator-runtime/runtime';
import md from 'micromarkdown';
import { StatusCodes } from 'http-status-codes';

type HTMLValidatableElement = HTMLFieldSetElement | HTMLFormElement | HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
type HTMLFormControlElement = HTMLButtonElement | HTMLFieldSetElement | HTMLInputElement | HTMLTextAreaElement;

type CardFormElements = {
    root: HTMLFormElement,
    values: HTMLFormControlsCollection,
    submit: HTMLButtonElement,
    alert: HTMLElement
}

function isHTMLFormControlElement(el: Element) {
    return el instanceof HTMLInputElement || el instanceof HTMLTextAreaElement;
}

export default abstract class CardForm {
    constructor(element: HTMLFormElement) {
        this.$elements = {
            root: element,
            values: element.elements,

            submit: element.querySelector('button[type=submit]'),
            alert: element.querySelector('[role=alert]')
        };
    }

    protected $elements: CardFormElements;  // TODO: This is an annoying idea that doesn't gel with subclassing. Get rid off of it.
    protected $submitting: boolean;

    initialize(params: URLSearchParams) {
        this.$elements.root.addEventListener('submit', e => this.onSubmit(e));
        Array.from(this.$elements.values).forEach((el: HTMLValidatableElement) => el.addEventListener('change', e => {
            if (e.srcElement === el) this.validationFeedback(el);
        }));
    }

    async onSubmit(event: Event) {
        // TODO: Animate the submit button while submitting
        event.preventDefault();

        // Don't allow re-entry (just in case somehow the form is submitted again)
        if (this.$submitting) return;

        this.$elements.alert.hidden = true;

        // Do native form validation
        if (!this.$elements.root.checkValidity()) return this.validationFeedback();

        try {
            // Disable the form submit button
            this.$elements.submit.setAttribute('disabled', 'disabled');
            this.$submitting = true;

            // Submit the form 
            let response = await this.submitForm(event);

            await this.onSubmitResponse(response);
        }
        catch (error) {
            await this.onSubmitError(error);
        }
        finally {
            // Enable the form again
            this.$elements.submit.removeAttribute('disabled');
            this.$submitting = false;
        }
    }

    protected async onSubmitResponse(response: Response) {
        if (!response.ok) {
            var content;
            try {
                content = await response.json();
            }
            catch (e) {
                // It's not even json...
                throw new Error('Ocurrió un error');
            }
            if (response.status === StatusCodes.BAD_REQUEST && content.modelState) {
                // Validation errors from the server. This ain't a real error, just inform the user

                // Update the validity of these fields
                // Copy the modelstate, keeping only interesting keys (ie.: not $type)
                const modelState = Object.keys(content.modelState)
                    .filter(k => !k.startsWith('$'))
                    .reduce((o, k) => {
                        o[k] = content.modelState[k];
                        return o;
                    }, {});

                Array.from(this.$elements.values)
                    .filter(isHTMLFormControlElement)
                    .filter((el: HTMLFormControlElement) => el.name && !(el instanceof HTMLInputElement && el.type === 'hidden'))
                    .forEach((el: HTMLFormControlElement) => {
                        // BUG: The server isn't casing correctly keys with dots (.)
                        //      We get some.Weirdly.Typed.Keys in the modelState, so
                        //      we search case-insensitively if needed
                        let { key, value: state } = findEntry(modelState, el.name);

                        let message = state ? state['$values'].join(' ') : '';
                        el.setCustomValidity(message);

                        if (key) delete modelState[key];
                    });

                this.validationFeedback();

                // If there's more ModelState that doesn't belong to a field, show it.
                if (Object.keys(modelState).length) {
                    // This is not a proper error that warrants tracing.
                    this.showAlert(Object.keys(modelState).flatMap(key => modelState[key]['$values']).join('\r\n'));
                }
            }
            else {
                // Some unexpected error from the server. Treat it as an error.
                throw Object.assign(new Error(content && (content.error_description || content.message) || response.statusText), { response, content });
            }
        }
        else {
            await this.onSubmitSuccess(response);
        }
    }

    protected abstract onSubmitSuccess(response: Response): Promise<void> | void;

    protected async onSubmitError(error: Error) {
        // Show in an alert
        this.showAlert(error.message);

        // Throw anyway, so tracing can pick it up.
        throw error;
    }

    protected showAlert(message: string) {
        if (this.$elements.alert) {
            this.$elements.alert.innerHTML = md.parse(message);
            this.$elements.alert.hidden = false;
        }
    }


    /**
     * Shows feedback from the constraint API
     * @param element An element to update the feedback
     */
    protected validationFeedback(element?: HTMLValidatableElement) {
        // Shows feedback from the constraint API
        if (!element) element = this.$elements.root;
        if (element instanceof HTMLFormElement || element instanceof HTMLFieldSetElement) {
            // Show feedback for the whole form/fieldset
            element.classList.add('was-validated');

            // Add or remove the feedback message
            Array.from(element.elements)
                .filter(isHTMLFormControlElement)
                .filter((el: HTMLFormControlElement) => !!el.name)
                .forEach(this.validationFeedback, this);
        }
        else {
            // Show feedback for a single element

            // Remove the existing feedback, if any
            Array.from(element.parentElement.children)
                .filter(e => e.classList.contains('invalid-feedback'))
                .forEach(e => e.remove());

            // And add new
            if (element.validationMessage) {
                let feedback = document.createElement('div');
                feedback.classList.add('invalid-feedback');
                feedback.innerText = element.validationMessage;
                element.parentElement.appendChild(feedback);
            }
        }
    }

    private submitForm(event: Event) {
        // TODO: The form's behavior when submitted is more complex than this.
        //       I think using the event would help us here.

        // Encode the body
        let body;
        switch (this.$elements.root.encoding) {
            case 'application/x-www-form-urlencoded':
                // FormData in IE10 doesn't work, so we can't just do
                // body = new URLSearchParams(new FormData(form))

                // Some explaining... form.elements contains every HTMLFormElement (inputs, buttons, fieldsets, etc).
                // Also, fieldset.disabled effectively is propagated to elements within, so they shouldn't be serialized.
                const elementsInDisabledFieldsets = Array.from(this.$elements.values)
                    .filter(el => el instanceof HTMLFieldSetElement && el.disabled)
                    .flatMap((fs: HTMLFieldSetElement) => Array.from(fs.elements));
                body = Array.from(this.$elements.values)                        // Get all elements in the forms
                    .filter(el => el instanceof HTMLInputElement || el instanceof HTMLTextAreaElement)
                    .filter((el: HTMLFormControlElement) => !!el.name)          // Except those that can't be posted for they have no name
                    .filter((el: HTMLFormControlElement) => !el.disabled)       // and those that are disabled
                    .filter(el => !elementsInDisabledFieldsets.includes(el))    // And those in a disabled fieldset.
                    .map((el: HTMLInputElement | HTMLTextAreaElement) => `${encodeURIComponent(el.name)}=${encodeURIComponent(el.value)}`)
                    .join("&");                                                 // And just put them together

                break;
            default:
                body = new FormData(this.$elements.root);
        }

        return fetch(this.$elements.root.action, {
            method: this.$elements.root.method,
            headers: {
                'Content-Type': this.$elements.root.encoding,
                'Accept': 'application/json'
            },
            body
        });
    }
}

function findEntry(o, key) {
    // Returns a pair {key, value} from o, searching case-insensitive
    if (!o.hasOwnProperty(key)) {
        key = Object.keys(o)
            .filter(k => k.toLowerCase() === key.toLowerCase())[0];
    }

    return { key, value: key && o[key] };
}
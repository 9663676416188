﻿import { Account } from './model/account';
import { FiscalId } from './model/fiscal-id';
import { Invitation } from './model/invitation';
import { Person } from './model/person';

export class ModelDeserializer {

    // TODO: This involves quite a bit of manual parsing. Can we do some kind of reflection or introspection?

    deserialize<T>(Type: new () => T, data: any): T {
        if (!data) return null;

        // TODO: Handle arrays thru data

        // TODO: This kind of deserialization doesn't allow readonly properties

        var product = new Type();
        switch (product.constructor) {
            case Account: this.deserializeAccount(<unknown>product as Account, data); break;
            case FiscalId: this.deserializeFiscalId(<unknown>product as FiscalId, data); break;
            case Invitation: this.deserializeInvitation(<unknown>product as Invitation, data); break;
            case Person: this.deserializePerson(<unknown>product as Person, data); break;
        }

        return product;
    }

    protected deserializeAccount(product: Account, data: any) {
        ['id', 'legalName'].forEach(k => product[k] = data[k]);
        product.members = (data.members || []).map(d => this.deserialize<Person>(Person, d));
    }

    protected deserializeFiscalId(product: FiscalId, data: any) {
        ['type', 'code'].forEach(k => product[k] = data[k]);
    }

    protected deserializeInvitation(product: Invitation, data: any) {
        ['code', 'mailAddress'].forEach(k => product[k] = data[k]);
        product.account = this.deserialize(Account, data.account);
        product.person = this.deserialize(Person, data.person);
    }

    protected deserializePerson(product: Person, data: any) {
        if (!data) return null;
        ['id', 'firstName', 'middleName', 'surname', 'gender', 'role', 'notifyAlerts'].forEach(k => product[k] = data[k]);
        product.identification = this.deserialize(FiscalId, data.identification);
    }

}
﻿import CardForm from './components/card-form';
import { isApiError } from './api/api-service-base';

class LoginForm extends CardForm {
    constructor(element: HTMLFormElement) {
        super(element);
        this.notes = Array.from(element.querySelectorAll('[role=note][id]'))
            .reduce((notes, el) => {
                notes[el.id] = el;
                return notes;
            }, {});
    }

    protected redirectRelativePath: string;
    protected notes: { [id: string]: HTMLElement };   // Map of HTMLElements indexed by the id

    initialize(params: URLSearchParams) {
        // Set the app id and redirect from the searchparams
        this.$elements.values['client_id'].value = params.get('app');
        
        // And rewrite any link with that same searchparam
        Array.from(this.$elements.root.querySelectorAll('a[data-keep-param=app]'))
            .forEach((a: HTMLAnchorElement) => {
                let href = new URL(a.href);
                href.searchParams.set('app', params.get('app'));
                a.href = href.href;
            });

        // Show notes as requested
        params.getAll('note')
            .map(name => this.notes[name])
            .filter(el => !!el)
            .forEach(el => el.hidden = false);

        // Handle redirections after the login
        if (params.has('redirect')) {
            var redirect = decodeURIComponent(params.get('redirect'));
            if (redirect.match(/^(https?:)?\/\//i)) {
                // If it's an absolute URL, then we'll send it whole in the request
                this.$elements.values['redirect_uri'].value = redirect;
            }
            else {
                // If it's relative, we'll append it to the server-provided redirect
                this.redirectRelativePath = redirect;
            }
        }
        super.initialize(params);
    }

    async onSubmitSuccess(response: Response) {
        let content = await response.json();

        // Redirect to wherever the server says
        let appUrl = new URL(this.redirectRelativePath || '', content['.redirect']);


        appUrl.searchParams.set('at', content.access_token);
        appUrl.searchParams.set('rt', content.refresh_token);
        appUrl.searchParams.set('tt', content.token_type);
        appUrl.searchParams.set('e', content.expires_in);

        window.location.assign(`${appUrl}`);
    }

    async onSubmitError(error: Error) {
        if (isApiError(error) && error.content && error.content.error === 'invalid_grant' && error.content.error_description) {
            // Soft error.
            this.showAlert(error.content.error_description);
        }
        else {
            // Go bananas
            await super.onSubmitError(error);
        }
    }
}


// Bootstrap this page
const form = new LoginForm(document.querySelector('form'));
const params = new URLSearchParams(window.location.search);

// Default
if (!params.has('app')) params.set('app', 'lithium.web');
if (!params.has('debug')) removeParam('note');

form.initialize(params);

function removeParam(name: string) {
    // Removes a search param from the current URL.
    const url = new URL(window.location.href);
    let result = url.searchParams.get(name);
    if (result) {
        url.searchParams.delete(name);
        window.history.replaceState({}, document.title, url.href);
    }
}

﻿import { AccountRole } from "./account-role";
import { Gender } from "./gender";
import { FiscalId } from './fiscal-id';

export class Person {
    public id: number;
    public firstName: string;
    public middleName: string;
    public surname: string;
    public gender: Gender;
    public role: AccountRole;
    public notifyAlerts: boolean;
    //public contacts: Contact[];
    public identification: FiscalId;

    toString() {
        return [this.firstName, this.middleName, this.surname].filter(n => !!n).join(' ');
    }
}